import {isDonationTicketDefinition, hasPricingOptions} from '@wix/wix-events-commons-statics'
import React from 'react'
import classNames from 'classnames'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {Tax} from '../../../parts/tax'
import {WixFee} from '../../../parts/wix-fee'
import {getFlexJustifyContentClass} from '../../../../alignment'
import {DONATION_ERROR} from '../../../../../../types'
import {Donation} from '../../../parts/donation'
import {checkDonationError} from '../../../../../../services/donations'
import {getPricingOptionsRangeLabel} from '../../../../../../selectors/seating/place'
import {useCurrencyFormatter} from '../../../../../../../../../commons/hooks/currency'
import s from './price.scss'
import {PriceProps} from './index'

export const Price = ({
  className,
  ticket,
  isMobile,
  donationInputVisible,
  contentAlignment,
  changeTicketDonation,
  donation,
  donationError,
  setDonationError,
}: PriceProps) => {
  const {t} = useTranslation()
  const {getFormattedMoney} = useCurrencyFormatter()

  const donationTicket = isDonationTicketDefinition(ticket)
  const pricingOptionsTicket = hasPricingOptions(ticket)

  let price: string
  if (donationTicket) {
    price = t('tickets.donation')
  } else if (pricingOptionsTicket) {
    price = getPricingOptionsRangeLabel(ticket, t, getFormattedMoney)
  } else {
    price = getFormattedMoney(ticket.price)
  }

  const onDonationChange = (donation: string) => {
    changeTicketDonation({ticketId: ticket.id, donation})
  }

  const onDonationBlur = () => {
    const error = checkDonationError(ticket, donation, {excludeErrors: [DONATION_ERROR.EMPTY_DONATION]})
    if (error) {
      setDonationError({ticketId: ticket.id, error})
    }
  }

  return (
    <div className={className} role="cell">
      {donationInputVisible ? (
        <Donation
          ticket={ticket}
          value={donation}
          onChange={onDonationChange}
          onBlur={onDonationBlur}
          error={donationError}
        />
      ) : (
        <p className={s.price} data-hook="price" aria-label={t('mobile.thanks.price', {price})}>
          {price}
        </p>
      )}

      {!pricingOptionsTicket ? (
        <div
          className={classNames({
            [s.taxFeeContainer]: isMobile,
            [getFlexJustifyContentClass(contentAlignment)]: isMobile,
          })}
        >
          <Tax ticket={ticket} overridePrice={donation} />
          <WixFee ticket={ticket} overridePrice={donation} />
        </div>
      ) : null}
    </div>
  )
}
