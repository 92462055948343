import React from 'react'
import classNames from 'classnames'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {WowImage, ImageResizeOptions, ImageLoadingBehaviorOptions} from 'wix-ui-tpa'
import {getImageStyle, resolveFocalPoint} from '../../../../../commons/utils/image'
import s from './event-image.scss'
import {classes} from './event-image.st.css'
import {EventImageProps} from './interfaces'

export const EventImage = ({
  containerRef,
  alt,
  container,
  image,
  opacity,
  position,
  fitImage,
  className,
  doubleHeight,
  isWUTImage,
}: EventImageProps) => {
  const {src, styles} = getImageStyle({
    image,
    container,
    opacity,
    position,
    superUpscaled: true,
  })

  const focalPoint = resolveFocalPoint(position)

  return (
    <div className={classNames(s.outerContainer, className)} ref={containerRef}>
      {!isWUTImage ? <div className={classNames(s.imageBackground, s.sizing, {[s.double]: doubleHeight})} /> : null}
      <div className={classNames(s.imageContainer, s.sizing, {[s.double]: doubleHeight})} data-hook={DH.eventImage}>
        {isWUTImage ? (
          <WowImage
            className={classes.image}
            sourceWidth={image.width}
            sourceHeight={image.height}
            src={image.id}
            alt={alt}
            focalPoint={focalPoint}
            resize={fitImage ? ImageResizeOptions.contain : ImageResizeOptions.cover}
            loadingBehavior={ImageLoadingBehaviorOptions.blur}
            fluid
          />
        ) : (
          <img className={s.image} src={src} style={styles} alt={alt} />
        )}
      </div>
    </div>
  )
}
